<template>
  <v-container>
    <v-row>
      <v-col cols="12">
          <l-map
            v-if="center"
            :zoom="zoom"
            :max-zoom="19"
            :center="center"
            :options="mapOptions"
                style="z-index: 7; height: 60vh; width: 100%"
          >
            <l-tile-layer
              :url="url"
              :attribution="attribution"
              :options="{ maxZoom: 19 }"
            />
            <l-marker v-if="carPosition" :lat-lng="carPosition">
              <l-icon
                  icon-url="/img/marker-icon.png"
                  shadow-url="/img/marker-shadow.png">
              </l-icon>
            </l-marker>
            <l-circle-marker v-if="currentPosition" :lat-lng="currentPosition">
            </l-circle-marker>
          </l-map>
      </v-col>
      <v-col cols="12">
        <v-select
          v-model="reportData.keyLocation"
          :items="items"
          label="Schlüsselort"
          @change="setKeyLocation"
        ></v-select>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from 'axios'
import 'leaflet/dist/leaflet.css'
import { latLng } from 'leaflet'
import { LMap, LTileLayer, LMarker, LIcon, LCircleMarker } from 'vue2-leaflet'

export default {
  name: 'MapView',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LCircleMarker
  },
  props: {
    chassisNumber: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      zoom: 18,
      center: false,
      currentPosition: false,
      url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      mapOptions: {
        zoomSnap: 0.5,
        dragging: false
      },
      error: false,
      items: ['Werkstatt', 'Schrank', 'Verkauf']
    }
  },
  methods: {
    setKeyLocation(val) {
      if (val !== 'null' && val !== '') {
        axios
          .post(`api/workshop/${this.chassisNumber}/edit/keyLocation/${val}`)
          .then(response => {
            if (response.data.status === 'Success') {
              console.log(response.data)
            } else {
              this.$store.commit('setError', { error: true, errorMessage: 'Fehler beim Speichern der Koordinaten' })
            }
          }).catch(() => {
            this.$store.commit('setError', { error: true, errorMessage: 'Fehler beim Speichern der Koordinaten' })
          })
      }
    }
  },
  watch: {
    chassisNumber: function (newVal) {
      this.$store.commit('setReportData', this.chassisNumber)
    }
  },
  mounted() {
    this.$store.commit('setReportData', this.chassisNumber)

    this.center = latLng('50.98857963030522', '13.81224011779137')
    if (!('geolocation' in navigator)) {
      this.error = 'Geolocation is not available.'
      return
    }
    // get position
    navigator.geolocation.watchPosition(pos => {
      this.currentPosition = latLng(pos.coords.latitude, pos.coords.longitude)
      this.center = latLng(pos.coords.latitude, pos.coords.longitude)

      if (pos.coords) {
        this.$store.commit('setCurrentLocation', pos)
      }
    }, err => {
      this.error = err.message
    })
  },
  computed: {
    reportData() { return this.$store.state.reportData },
    carPosition() {
      if (this.reportData && this.reportData.location) {
        return latLng(this.reportData.location.lat, this.reportData.location.long)
      } else {
        return false
      }
    }

  }
}
</script>

<style lang="scss" scoped>
</style>
