<template>
    <v-main >
  <v-app-bar
    app
  >

      <v-btn
        elevation="2"
        x-large
        to="/"
       >
        <v-icon>
          mdi-arrow-left
        </v-icon>

        <span>Zurück</span>
      </v-btn>
    <v-spacer></v-spacer>
      <template v-if="currentRoute !== 'mobile'">
        <v-btn
          elevation="2"
          x-large
          @click.stop="sendPage"
          exact
          class="d-none-only"
          v-show="currentRoute === 'form'"
        >
          <v-icon>
            mdi-send
          </v-icon>

          <span class="hidden-sm-and-down">Senden</span>
        </v-btn>
        <v-btn
          elevation="2"
          x-large
          @click.prevent="printPage"
          class="d-none-only"
          v-show="currentRoute === 'form'"
        >
          <v-icon>
            mdi-printer
          </v-icon>

          <span class="hidden-sm-and-down">Drucken</span>
        </v-btn>

        <v-btn
          v-show="currentRoute !== 'form'"
          elevation="2"
          x-large
          :to="'/' + chassisNumber"
          exact
          class="d-none-only"
         >
          <v-icon>
            mdi-file-document-outline
          </v-icon>
          <span class="hidden-sm-and-down">Formular</span>
        </v-btn>

        <v-btn
          v-show="currentRoute !== 'winter'"
          elevation="2"
          x-large
          :to="'/' + chassisNumber + '/winter_wheels'"
          exact
          class="d-none-only"
         >
          <v-icon>
            mdi-snowflake
          </v-icon>
          <span class="hidden-sm-and-down">Winterreifen</span>
        </v-btn>

        <v-btn
          v-show="currentRoute !== 'map'"
          elevation="2"
          x-large
          :to="'/' + chassisNumber + '/map'"
          exact
          class="d-none-only"
         >
          <v-icon>
            mdi-earth
          </v-icon>
          <span class="hidden-sm-and-down">Karte</span>
        </v-btn>

        <v-btn
          elevation="2"
          x-large
          @click.stop="toggleCamera"
          class="d-none-only"
          v-show="currentRoute === 'form'"
         >
          <v-icon>
            mdi-camera
          </v-icon>

          <span class="hidden-sm-and-down">Kamera</span>
        </v-btn>
      </template>
      <v-btn
        elevation="2"
        x-large
        @click.stop="setLocation"
        class="d-flex d-sm-none"
      >
        <v-icon>
          mdi-content-save
        </v-icon>

        <span class="hidden-sm-and-down">Speichern</span>
      </v-btn>
  </v-app-bar>
  <v-alert
    type="error"
    v-if="error">
    {{ error }}
    <template v-slot:close="{ toggle }">
      <v-btn icon @click="closeAlert(toggle)">
        <v-icon>mdi-close-circle</v-icon>
      </v-btn>
    </template>
  </v-alert>
  <router-view />
    <LoadingDialog v-if="loading"></LoadingDialog>
    </v-main>
</template>
<script>

import axios from 'axios'
import LoadingDialog from './LoadingDialog.vue'

export default {
  name: 'BaseView',
  components: {
    LoadingDialog
  },
  props: {
    chassisNumber: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      currentRoute: '',
      loading: false
    }
  },
  watch: {
    $route(to, from) {
      this.getCurrentRoute()
    }
  },
  mounted() {
    this.$store.commit('setReportData', this.chassisNumber)
    this.getCurrentRoute()
  },
  methods: {
    getCurrentRoute() {
      if (this.$vuetify.breakpoint.name === 'xs') {
        this.currentRoute = 'mobile'
      } else if (this.$router.currentRoute.fullPath.includes('map')) {
        this.currentRoute = 'map'
      } else if (this.$router.currentRoute.fullPath.includes('winter')) {
        this.currentRoute = 'winter'
      } else {
        this.currentRoute = 'form'
      }
    },
    printPage() {
      this.loading = true
      axios
        .get(`api/workshop/${this.chassisNumber}/print`, {
          headers: {
            'Content-type': 'application/json'
          },
          Accept: 'application/pdf',
          responseType: 'blob'
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'befund_' + this.chassisNumber + '.pdf')
          document.body.appendChild(link)
          link.click()
        }).catch(() => {
          this.$store.commit('setError', { error: true, errorMessage: 'Fehler beim erstellen der PDF' })
        }).finally(() => {
          this.loading = false
        })
    },
    sendPage() {
      this.loading = true
      axios
        .get(`api/workshop/${this.chassisNumber}/send`)
        .then(response => {
          console.log(response)
        }).catch(() => {
          this.$store.commit('setError', { error: true, errorMessage: 'Fehler beim Senden der PDF' })
        }).finally(() => {
          this.loading = false
        })
    },
    toggleCamera() {
      this.$store.commit('toggleCamera')
    },
    toggleParking() {
      this.$store.commit('toggleParking')
    },
    setLocation() {
      this.$store.commit('setLocation')
    },
    closeAlert() {
      this.$store.commit('setError', { error: false })
    }
  },
  computed: {
    error() { return this.$store.state.error }
  }
}
</script>

<style lang="scss">
  @media print {
    .v-main{
      padding-top:0 !important;
    }
    header{
      display:none !important
    }
    .row{
      margin:0 !important;
    }
    .col{
      padding-top:5px !important;
      padding-bottom:5px !important;
    }
    .page-break {
      break-before: page !important
    }
    input[type=radio],
    {
      transform: scale(1);
      padding: 5px;
    }

    .extra-table{
      td{
        padding: 10px;
      }

      input{
        margin-left:5px;
      }
    }
  }
</style>
