<template>
  <v-container class="fill-height">
    <v-row>
      <v-col cols="10" md="6" offset-md="3" offset="1" class="d-flex align-center">
        <v-text-field
          label="Fahrgestellnummer eingeben"
          hint="Min. die letzten 5 Ziffern der VIN eintragen"
          v-model="vin"
          :error="error"
          :error-messages="errorMessage"
          :loading="isLoading"
          @keyup.enter="findCar"
        ></v-text-field>
      </v-col>

      <v-col cols="10" md="6" offset-md="3" offset="1" class="d-flex align-center justify-center">
        <v-btn
          @click.stop="findCar"
        >
          Fahrzeug suchen
        </v-btn>
      </v-col>

      <v-col cols="10" md="6" offset-md="3" offset="1" class="d-flex align-center" v-if="items">
        <v-card
            class="mx-auto"
            max-width="400"
            tile
          >
          <template v-if="breakpoint=== 'xs'">
            <v-list-item two-line v-for="item in items" :key="item.number" link :to="'/' + item.vin">
              <v-list-item-content>
                <v-list-item-title>{{ item.vin }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.brand }} {{ item.model }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-else>
            <v-list-item two-line v-for="item in items" :key="item.number">
              <v-list-item-content>
                <v-list-item-title>{{ item.vin }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.brand }} {{ item.model }}</v-list-item-subtitle>
              <v-list-item>
                <v-btn :to="'/' + item.vin">
                  Formular öffnen
                </v-btn>
              </v-list-item>
              <v-list-item>
                <v-btn :to="'/' + item.vin + '/winter_wheels'">
                  Winterräder öffnen
                </v-btn>
              </v-list-item>
              <v-list-item>
                <v-btn :to="'/' + item.vin + '/map'">
                  Karte öffnen
                </v-btn>
                </v-list-item>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Home',
  data() {
    return {
      vin: '',
      items: null,
      error: false,
      errorMessage: '',
      breakpoint: null,
      isLoading: false
    }
  },
  mounted() {
    this.breakpoint = this.$vuetify.breakpoint.name
  },
  methods: {
    findCar() {
      if (this.vin.length > 4) {
        this.errorMessage = ''
        this.error = false
        this.isLoading = true
        axios
          .get(`api/workshop/${this.vin}/vin`)
          .then(response => {
            if (response.data.status === 'Success') {
              this.items = []
              if (!response.data.data.vehicles.length) {
                this.items.push(response.data.data.vehicles)
              } else {
                response.data.data.vehicles.forEach(vehicle => {
                  this.items.push(vehicle)
                })
              }
            }
          }).catch(() => {
            this.$store.commit('setError', { error: true, errorMessage: 'Fehler beim Laden des Fahrzeugs' })
          })
          .finally(() => (this.isLoading = false))
      } else {
        this.errorMessage = 'Geben Sie mindestens 5 Ziffern an!'
        this.error = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
