<template>
  <v-container>
    <v-row>
      <v-col cols="12">
          <l-map
            v-if="center"
            :zoom="zoom"
            :max-zoom="35"
            :center="center"
            :options="mapOptions"
                style="z-index: 7; height: 90vh; width: 100%"
          >
            <l-tile-layer
              :url="url"
              :attribution="attribution"
              :options="{ maxNativeZoom:18, maxZoom: 35 }"
            />
            <l-marker v-for="(position, index) in carPosition" :lat-lng="position" :key="index">
              <l-icon
                  icon-url="/img/marker-icon.png"
                  shadow-url="/img/marker-shadow.png">
              </l-icon>
            </l-marker>
          </l-map>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from 'axios'
import 'leaflet/dist/leaflet.css'
import { latLng } from 'leaflet'
import { LMap, LTileLayer, LMarker, LIcon } from 'vue2-leaflet'

export default {
  name: 'CompleteMapView',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon
  },
  data() {
    return {
      zoom: 18,
      center: false,
      url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      mapOptions: {
        zoomSnap: 0.5
      },
      error: false,
      carPosition: []
    }
  },
  methods: {
  },
  mounted() {
    this.center = latLng('50.98857963030522', '13.81224011779137')

    axios
      .get('api/workshop/locations')
      .then(response => {
        if (response.data.status === 'Success') {
          response.data.data.forEach(location => {
            this.carPosition.push(latLng(location.lat, location.long))
          })
        } else {
          this.$store.commit('setError', { error: true, errorMessage: 'Fehler beim ermitteln der Koordinaten' })
        }
      }).catch(() => {
        this.$store.commit('setError', { error: true, errorMessage: 'Fehler beim ermitteln der Koordinaten' })
      })
  },
  computed: {
  }
}
</script>

<style lang="scss" scoped>
</style>
