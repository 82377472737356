<template>
  <v-form id="document">
    <v-container>
      <v-row>
        <v-col cols="6">
          <v-text-field
              label="Fahrgestellnummer"
              tabindex="1"
              v-model="chassisNumber"
              :readonly="true"
          >
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
              label="Vorbesitzer"
              tabindex="2"
              v-model="wheelData.previousOwner"
              @blur="updateReportData('previousOwner')"
          >
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
              label="Reifenhersteller"
              tabindex="3"
              v-model="wheelData.manufacturer"
              @blur="updateReportData('manufacturer')"
          >
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
              label="Reifengröße"
              tabindex="4"
              v-model="wheelData.tireSize"
              v-mask="'###/## A##'"
              @blur="updateReportData('tireSize')"
          >
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
              label="Felgengröße"
              tabindex="5"
              v-model="wheelData.rimSize"
              @blur="updateReportData('rimSize')"
          >
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
              label="Einpresstiefe"
              tabindex="6"
              v-model="wheelData.offset"
              @blur="updateReportData('offset')"
          >
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
              label="Lochbefestigung"
              tabindex="7"
              v-model="wheelData.holeMounting"
              @blur="updateReportData('holeMounting')"
          >
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
              label="Herstellungsmonat"
              tabindex="8"
              v-model="wheelData.manufacturingMonth"
              v-mask="'##/##'"
              @blur="updateReportData('manufacturingMonth')"
          >
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
              label="Profiltiefe mm"
              tabindex="9"
              v-model="wheelData.profileDepth"
              @blur="updateReportData('profileDepth')"
          >
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
              label="Reifensatznummer"
              tabindex="10"
              v-model="wheelData.tireSetNumber"
              @blur="updateReportData('tireSetNumber')"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import axios from 'axios'

export default {
  name: 'WinterWheels',
  components: {
  },
  props: {
    chassisNumber: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
    }
  },
  methods: {
    updateReportData(type) {
      var value = this.wheelData[type]

      if (type === 'manufacturingMonth' || type === 'tireSize') {
        value = value.replace('/', '')
      }

      axios
        .post(`api/workshop/${this.chassisNumber}/edit/winter_wheels_${type}/${value}`)
        .then(response => {
          if (response.data.status === 'Success') {
            console.log(response.data)
          } else {
            this.$store.commit('setError', { error: true, errorMessage: 'Fehler beim Speichern der Daten' })
          }
        }).catch(() => {
          this.$store.commit('setError', { error: true, errorMessage: 'Fehler beim Speichern der Daten' })
        })
    }
  },
  computed: {
    wheelData() { return this.$store.state.wheelData }
  }
}
</script>

<style lang="scss" scoped>
</style>
