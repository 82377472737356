import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../components/Home.vue'
import MapView from '../components/MapView.vue'
import WinterWheels from '../components/WinterWheels.vue'
import CompleteMapView from '../components/CompleteMapView.vue'
import BaseView from '../components/BaseView.vue'
import responsiveRoute from '../mixins/RouterMixin.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/complete-map',
    name: 'Map',
    component: CompleteMapView
  },
  {
    path: '/:chassisNumber?',
    component: BaseView,
    children: [
      {
        path: '',
        component: responsiveRoute({
          xs: () => import('../components/MapView.vue'),
          sm: () => import('../components/ReportForm.vue')
        }),
        props: true
      },
      {
        path: 'map',
        component: MapView,
        props: true
      },
      {
        path: 'winter_wheels',
        component: WinterWheels,
        props: true
      }
    ],
    props: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
