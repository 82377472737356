import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '@/router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showCamera: false,
    showParking: false,
    showParkingMap: false,
    matrixRowItems: [
      { key: 'Frontblech', value: 'frontPanel' },
      { key: 'Motorhaube', value: 'hood' },
      { key: 'Dach', value: 'roof' },
      { key: 'Kofferdeckel', value: 'trunkLid' },
      { key: 'Rückwand/Hecktür', value: 'panelDoorRear' },
      { key: 'Kofflügel vorn re/li', value: 'frontLuggageWing' },
      { key: 'Vordertüren re/li', value: 'frontDoors' },
      { key: 'Hintertüren re/li', value: 'rearDoors' },
      { key: 'Kotflügel hinten re/li', value: 'RearFender' },
      { key: 'Seitenschweller re/li', value: 'sideSkirts' },
      { key: 'Seitenteil kompl. re', value: 'sidePanelRight' },
      { key: 'Seitenteil kompl. li', value: 'sidePanelLeft' },
      { key: 'Vorderwagen kompl.', value: 'frontEnd' },
      { key: 'Wagenheck kompl.', value: 'rearEnd' },
      { key: 'Ganzlackierung', value: 'allPaint' },
      { key: 'Stoßfänger vorn', value: 'frontBumper' },
      { key: 'Stoßfänger hinten', value: 'rearBumber' },
      { key: 'Unterboden', value: 'underBody' }
    ],
    matrixColumnItems: [
      { key: 'Lackstift', value: 'touchUpPencil' },
      { key: 'Steinschlagsch.', value: 'stoneChip' },
      { key: 'Beulen', value: 'dents' },
      { key: 'Kantenrost', value: 'edgeRust' },
      { key: 'Unterrostungen', value: 'underRusting' },
      { key: 'Durchrostungen', value: 'rustThrough' },
      { key: 'Lackieren', value: 'paint' },
      { key: 'DentWizard', value: 'dentWizard' },
      { key: 'Polieren', value: 'polishing' }
    ],
    reports: {

    },
    reportData: {
      ownerName: '',
      ownerStreet: '',
      ownerZip: '',
      ownerCity: '',
      date: '',
      responsible: '',
      interestFor: '',
      manufacturer: '',
      capacity: '',
      chassisNumber: '',
      licensePlate: '',
      color: '',
      doors: '',
      initialRegistration: '',
      speedoReading: '',
      nextAppointment: '',
      serviceBooklet: 0,
      tires: '',
      inspection: 0,
      optic: '',
      accessories: '',
      damaged: 0,
      damages: '',
      carBodyNote: '',
      carBodyCosts: null,
      engineNote: '',
      engineCosts: null,
      gearboxNote: '',
      gearboxCosts: null,
      frontAxleNote: '',
      frontAxleCosts: null,
      wheelBearingNote: '',
      wheelBearingCosts: null,
      steeringNote: '',
      steeringCosts: null,
      rearAxleNote: '',
      rearAxleCosts: null,
      brakesNote: '',
      brakesCosts: null,
      exhaustNote: '',
      exhaustCosts: null,
      batteryNote: '',
      batteryCosts: null,
      tiresNote: '',
      tiresCosts: null,
      polsteryNote: '',
      polsteryCosts: null,
      opticNote: '',
      opticCosts: null,
      inspectionNote: '',
      inspectionCosts: null,
      diverseNote: '',
      diverseCosts: '',
      matrix: [],
      images: [],
      location: {
        lat: null,
        long: null
      }
    },
    matrixData: [],
    wheelData: [],
    currentLocation: {},
    error: false
  },
  mutations: {
    loadReports(state) {
      axios
        .get('api/workshop/load')
        .then(response => {
          if (response.data.status === 'Success') {
            state.reports = response.data.data
          }
        }).catch(() => {
        })
    },
    setReportData(state, chassisNumber) {
      if (chassisNumber && chassisNumber !== 'null' && chassisNumber !== '') {
        axios
          .get(`api/workshop/${chassisNumber}/load`)
          .then(response => {
            if (response.data.status === 'Success') {
              state.reportData = response.data.data
              state.wheelData = response.data.data.winter_wheels
              state.matrixData = []

              for (const entry in response.data.data.matrix) {
                if (response.data.data.matrix[entry] === 1) {
                  state.matrixData.push(entry)
                }
              }
            }
          }).catch(() => {
          })
      }
    },
    setCurrentLocation(state, location) {
      state.currentLocation.long = location.coords.longitude
      state.currentLocation.lat = location.coords.latitude
    },
    setLocation(state) {
      axios
        .post(`api/workshop/${state.reportData.chassisNumber}/location`, { long: state.currentLocation.long, lat: state.currentLocation.lat })
        .then(response => {
          if (response.data.status === 'Success') {
            state.reportData = response.data.data
            router.push('/')
          }
        }).catch(() => {
        })
    },
    setMatrixData(state, matrix) {
      state.matrixData = matrix
    },
    toggleCamera(state) {
      state.showCamera = !state.showCamera
    },
    toggleParking(state) {
      state.showParking = !state.showParking
    },
    setError(state, payload) {
      if (payload.error === true) {
        state.error = payload.errorMessage
      } else {
        state.error = false
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
